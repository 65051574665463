.ra-badge {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: -8px;
  z-index: 2;

  &__content {
    font-size: var(--ra-font-size-body-s);
  }

  &--with-border {
    border: 1px solid;
  }

  &--small-font {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 10px;
      line-height: 10px;
      font-weight: 600;
    }
  }
}
